function md2html(mdTable) {
  // split the markdown table to get a table of rows
  const rows = mdTable.split("\n")

  /*
  rows[0] is the Header of the table
  We need to split the header row to get the columns
  Then delete the first and last columns, we don't need them
  Finally we can replace the content with the HTML
  */
  let thead = rows[0].split("|").slice(1)
  thead.pop()
  thead = thead.map(th => `<th>${th.trim()}</th>`).join("\n")

  /*
  The rest of rows starts from row 3, se we need to delete the first 2 rows
  Then split each row to get the columns, and of course, delete the first and last columns
  After that, we can replace the content of each column with the HTML
  And for each row, we need to add the <tr> tag
  */
  const tbody = rows
    .slice(2)
    .map(row => {
      let cell = row.split("|").slice(1)
      cell.pop()
      let result = cell.map(td => `<td>${td.trim()}</td>`).join("\n")
      return `<tr>\n${result}\n</tr>`
    })
    .join("\n")

  /*
  Finally, we can return the header inside a <thead> tag, and the body inside the <tbody> tag
  And all the content should be wrapped inside <table> tag
  */
  return `<table>\n  <thead>\n${thead}\n  </thead>\n  <tbody>\n${tbody}\n  </tbody>\n</table>`
}

export { md2html }
