import ShortcodeParser from "meta-shortcodes"

import { md2html } from "./md2html"
import arrow_icon from "../images/icon_arrow_blue.svg"

const parser = new ShortcodeParser()

/* 
ShortCode to put content in the left
Example of image in float-left shortCode : https://www.sorpetaler.de/hubert/nachhaltig-bauen-und-sanieren/holzhaus-wettbewerb-verlaengert/
Article's slug : nachhaltig-bauen-und-sanieren/holzhaus-wettbewerb-verlaengert 
*/
parser.add("float-left", function (_, content) {
  return `<div class="float-left">${content}</div>`
})

/*
ShortCode to put text in a card
Example of article using highlight : https://www.sorpetaler.de/hubert/allgemein/holzart-bestimmen/
Article's slug : holzart-bestimmen
*/
parser.add("highlight", function (_, content) {
  return `<div class="highlight"><p>${content}</p></div>`
})

/*
ShortCode to create a card with link, text and image
xample of article using card with link and image : https://www.sorpetaler.de/hubert/allgemein/holzart-bestimmen/
Article's slug : holzart-bestimmen
*/
parser.add("text-with-link", function (opts, content) {
  const linkText = opts["link-text"]
  const linkUrl = opts["link-url"]

  return `<div class="text-with-link">
            <div>
                <div>
                    <img src="${opts.image}" width="415" height="354" alt=${linkText}/>
                </div>
                <div>
                    <div>${content}</div>
                    <img src="${arrow_icon}" alt=${linkText}/>
                    <a href=${linkUrl}>${linkText}</a>
                </div>
            </div>
          </div>`
})

/*
ShortCode to embed a video with a URL link
Example of article with embedded video: https://www.sorpetaler.de/hubert/der-werkstoff-holz/was-macht-ein-restaurator/
Article's slug : was-macht-ein-restaurator
*/
parser.add("embed-video", function (opts) {
  const linkUrl = opts["link-url"]

  return `<div class="embed-video">
            <iframe src=${linkUrl} frameborder="0">
            </iframe>
          </div>`
})

/*
ShortCode to embed a post with a URL link (it can be an instagram post for example)
Example of article with embedded instagram post: https://www.sorpetaler.de/hubert/der-werkstoff-holz/holzmoebel-selber-bauen/
Article's slug : holzmoebel-selber-bauen
*/
parser.add("embed-post", function (opts) {
  const linkUrl = opts["link-url"]

  return `<div class="embed-post">
            <iframe src=${linkUrl} frameborder="0">
            </iframe>
          </div>`
})

/*
ShortCode to say that we're putting a table here, so we can convert the table to HTML
Example of article with table : https://www.sorpetaler.de/hubert/allgemein/holzart-bestimmen/
Article's slug : holzart-bestimmen
*/
parser.add("table", function (_, content) {
  return `${md2html(content)}`
})

export function parse(content) {
  return parser.parse(content)
}
